<template>
  <dash-page-new
      :title="$t('Headers')"
  >
    <h5>Headers list will be here</h5>
  </dash-page-new>
</template>

<script>
export default {
  name: "adminHeaders"
}
</script>

<style scoped>

</style>